@import "../../../assets/scss/variables.scss";

.app-store-locations {
  .data-wrapper {
    margin-top: 3rem;
    overflow: hidden;
  }
}

.app-store-location-edit {
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: $dark-grey-1;
    margin: 0;
    margin-bottom: 1rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
  }
}
