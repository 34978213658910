@import "../../../../assets/scss/variables.scss";

.app-release-list {
  .posts-table-wrapper {
    margin-top: 3rem;
    overflow: hidden;
  }

  .ic-elite {
    width: 17px;
  }
}
