@import "../../../../assets/scss/variables.scss";

.app-edit-release {
  .title {
    padding: 0 1rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
  }

  .thumbnail-wrapper {
    position: relative;

    .thumbnail {
      width: 100%;
      height: 142.6px;
      border: 1px solid rgba($dark-grey-1, 0.15);
      object-fit: contain;
    }

    .btn-delete {
      position: absolute;
      right: 5px;
      top: 5px;
      opacity: 0;
    }

    .thumb-overlay {
      position: absolute;
      background-color: none;
      top: 0;
      height: 100%;
      width: 100%;
      transition: background-color 200ms linear;

      &:hover {
        background-color: rgba(black, 0.35);
      }

      &:hover .btn-delete {
        opacity: 1;
      }
    }
  }

  .btn-row {
    display: flex;
    gap: 16px;

    .btn-template {
      height: 40px;
    }
  }

  .custom-upload {
    .ant-upload {
      width: 100%;
    }

    .btn-upload {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: #eee;
      align-items: center;
      border: none;
      padding: 24px 10px;
      border-radius: 10px;
    }
  }
}
