@import "../../../assets/scss/variables.scss";

.app-shop-details {
  .shop-image {
    width: 100%;
    object-fit: cover;
    height: 540px;
  }

  .form-wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
  }

  .img-upload-wrapper {
    display: block;

    .ant-upload {
      width: 100%;
    }

    .ant-btn {
      width: 100% !important;
      height: 40px !important;
      border: 1px solid #333333 !important;
      font-size: 1rem !important;
      background: $background;

      &:hover {
        color: $pure-white !important;
        background: $dark-grey-1 !important;
      }
    }
  }
}

.app-update-password {
  .help-text {
    color: $light-grey-2;
    margin-bottom: 1.5rem;
    white-space: pre-wrap;
  }

  .ant-form-item-label {
    text-align: left !important;
  }
}
