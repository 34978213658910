@import "../../../assets/scss/variables";

.app-users {
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: $dark-grey-1;
    margin-bottom: 1rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
  }
}
