@import "../../../assets/scss/variables.scss";

.app-shop-releases {
  .data-wrapper {
    margin-top: 3rem;
    overflow: hidden;
  }

  .btn-launch-draw {
    width: 200px;
  }

  .ic-elite {
    width: 17px;
  }
}
