.app-stores {
  .stores-table-wrapper {
    overflow: hidden;
    margin-top: 3rem;
  }

  .btn-raffle {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
