@import "../../../../assets/scss/variables.scss";

.app-posts {
  .btn-publish-unpublish {
    width: 94.27px;
  }

  .posts-table-wrapper {
    margin-top: 3rem;
    overflow: hidden;
  }
}
