@import "../../assets/scss/variables";

@mixin badge {
  padding: 5px 15px;
  height: 26px;
  text-align: center;
  justify-content: center;
}

.badge-primary-outline {
  @include badge;
  font-size: 12px;
  font-weight: 600;
  color: $dark-grey-1;
  border-radius: 12.5px;
  border: 1px solid rgba($dark-grey-1, 0.5);
}

.badge-primary {
  @include badge;
  font-size: 12px;
  font-weight: 600;
  color: $pure-white;
  background: $dark-grey-1;
  border-radius: 12.5px;
}
