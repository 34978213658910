.app-create-edit-raffle {
  .title {
    padding: 0 1rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 2.5rem;
    border-radius: 10px;

    .shoe-size-form-item {
      display: grid;
      grid-template-columns: 35px auto;
      align-items: center;
      margin-bottom: 1.5rem !important;
    }
  }

  .ant-row .ant-form-item {
    margin-bottom: 4px;
  }

  .btn-row {
    display: flex;
    gap: 16px;
  }
}
