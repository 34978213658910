.app,
body,
.wrapper,
.quill,
.app .ql-content,
.app .ql-editor {
  height: 55vh;
}

.app {
  margin: 1rem 0rem 4rem 0rem;
}

.app .ql-container {
  background: #fefcfc;
}


/* Snow Theme */

.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
}
