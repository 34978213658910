.mb-0 {
  margin-bottom: 0 !important;
}

//.mt-0 {
//  margin-top: 0 !important;
//}
//
//.mt-1 {
//  margin-top: 0.5rem !important;
//}
//
//.mt-2 {
//  margin-top: 1rem !important;
//}
//
//.mt-3 {
//  margin-top: 1.5rem !important;
//}

.r-1 {
  border-radius: 0.75rem !important;
}

//.overflow-hidden {
//  overflow: hidden !important;
//}

//.w-100 {
//  width: 100% !important;
//}
//
//.w-50 {
//  width: 50% !important;
//}


.f-grow-1 {
  flex-grow: 1 !important;
}

//.d-flex {
//  display: flex !important;
//}
//
//.align-items-center {
//  align-items: center !important;
//}

.font-bold {
  font-weight: bold !important;
}

//.text-underline {
//  text-decoration: underline !important;
//}
