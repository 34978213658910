$background: #f1f1f1;

$pure-white: #ffffff;

$dark-grey-1: #333333;

$light-grey-1: #eeeeee;
$light-grey-2: #979797;
$light-grey-3: #ebebeb;

$green-1: #27ae60;

$warning: #ffac32;
$success: #00ae00;
$danger: #eb5757;