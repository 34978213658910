@import "../../assets/scss/variables";

.app-side-nav-head {
  display: flex;
  gap: 0.65rem;
  align-items: center;
  border-bottom: 1px solid rgba($pure-white, 0.25);
  //margin-bottom: 1.3rem;
  padding: 1.5rem;

  .logo {
    width: 27px;
    object-fit: cover;
  }

  .user {
    flex-grow: 1;
    color: $pure-white;

    .name {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0;
    }

    .role {
      margin-bottom: 0;
      font-size: 0.75rem;
    }
  }
}
