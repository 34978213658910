@import "./variables.scss";
@import "./base.scss";

.logo {
  max-width: 50%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: $dark-grey-1;
  margin: 0;
}

.ant-layout {
  background-color: $background !important;
}

.ant-upload-wrapper {


  .ant-btn-default {
    box-shadow: none !important;
    //border-radius: 10px;
    &:hover {
      color: rgba($dark-grey-1, .75) !important;
    }
  }

  .ant-upload-select {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
}

.sd-bg-primary {
  background-color: $dark-grey-1 !important;
}

.ant-layout-sider {
  .ant-menu {
    padding-right: 2px;
    font-weight: bold;
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
    border-right: 4px solid $light-grey-1 !important;
  }

  .ant-layout-sider-trigger {
    background-color: $dark-grey-1 !important;
  }
}

.ant-table-thead > tr > th {
  background-color: #eaeaea !important;
}

.app-section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 25px;
}

.box-shadow {
  box-shadow: 0px 0px 5px 2px #E5E5E5;
}

.btn-submit,
.btn-submit:active,
.btn-submit:focus {
  height: 40px;
  width: 100%;
  background-color: $dark-grey-1;
  border-radius: 5px;
  outline: none;
  border: 2px solid $dark-grey-1 !important;
  font-size: 1rem;
  padding: 0 1.5rem !important;
  color: white;

  &:disabled {
    background-color: $light-grey-3;
    border-color: $light-grey-3 !important;
    color: $light-grey-2;

    &:hover {
      background-color: $light-grey-3;
      border-color: $light-grey-3 !important;
      color: $light-grey-2 !important;
    }
  }

  &:hover {
    color: $dark-grey-1 !important;
    background-color: $pure-white;
  }
}

.round-top-1 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.btn-outline-success {
  color: $success;
  background: $pure-white;
  border-color: $success;

  &:focus, &:hover {
    color: $pure-white !important;
    background: $success;
    border-color: $success !important;
  }
}

.btn-outline-warning {
  color: $warning;
  background: $pure-white;
  border-color: $warning;

  &:focus, &:hover {
    color: $pure-white !important;
    background: $warning;
    border-color: $warning !important;
  }
}

.btn-outline-danger {
  color: $danger;
  background: $pure-white;
  border-color: $danger;

  &:focus, &:hover {
    color: $pure-white !important;
    background: $danger;
    border-color: $danger !important;
  }
}

.btn-outline-primary {
  color: $dark-grey-1;
  background: $pure-white;
  border-color: $dark-grey-1;

  &:focus, &:hover {
    color: $pure-white !important;
    background: $dark-grey-1;
    border-color: $dark-grey-1 !important;
  }
}

.badge-primary {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 25px;
  color: $pure-white;
  background: $dark-grey-1;
  border-radius: 12.5px;
}

.badge-success {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 25px;
  color: $pure-white;
  background: $success;
  border-radius: 12.5px;
}

.ant-pagination-next,
.ant-pagination-prev {
  height: 45px;
  width: 45px;

  .ant-pagination-item-link {
    background: #333;
    border: none;
    color: white;
    border-radius: 5px;
  }
}

.ant-pagination-disabled {
  .ant-pagination-item-link {
    background: #dbdde0 !important;
  }
}

.ant-pagination-item {
  background-color: transparent !important;
}
